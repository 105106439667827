import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from "../../../components/BlogPost";
import Browser from "../../../components/BrowserDemo";
import Lesson from "../../../components/Lesson";
export const meta = {
  published: true,
  publishedAt: "2018-02-7",
  title: "Streaming Server-Side Rendering and Caching at Spectrum",
  summary: "We recently implemented streaming server-side rendering and caching for Spectrum, here is how",
  image: "/static/images/streaming-ssr.png",
  likes: 502
};
export const _frontmatter = {};
const layoutProps = {
  meta,
  _frontmatter
};

const MDXLayout = ({
  children
}) => <BlogPost meta={meta}>{children}</BlogPost>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><em parentName="p">{`Originally published on the `}<a parentName="em" {...{
          "href": "https://web.archive.org/web/20180211132841if_/https://zeit.co/blog/streaming-server-rendering-at-spectrum"
        }}>{`Zeit blog`}</a></em></p>
    <p>{`React 16 introduced streaming server-side rendering, which allows you to send down HTML in chunks in parallel to rendering it. This enables a faster time to first byte and first meaningful paint for your users since the initial markup arrives in the browser sooner.`}</p>
    <p><img parentName="p" {...{
        "src": "/static/images/renderToString.png",
        "alt": null
      }}></img></p>
    <p>{`Streams are also asynchronous, contrary to `}<inlineCode parentName="p">{`renderToString`}</inlineCode>{`, and handle backpressure well. This enables your Node.js server to render multiple requests at the same time and stay responsive in challenging conditions. It can pause React's rendering if the network is saturated, and makes it so heavier requests doesn't block lighter requests for a prolonged period of time. Since we were having some performance issues in our server-side rendering worker for `}<a parentName="p" {...{
        "href": "https://spectrum.chat"
      }}>{`Spectrum`}</a>{` we recently implemented streaming, which turned out to have an interesting impact on our HTML caching.`}</p>
    <h3>{`Using `}<inlineCode parentName="h3">{`renderToNodeStream`}</inlineCode></h3>
    <p>{`The API for streaming server-side rendering isn't all that different from the standard `}<inlineCode parentName="p">{`renderToString`}</inlineCode>{` API. Here's what it looks like:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` renderToNodeStream `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'react-dom/server'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Frontend`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'../client'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

app`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`use`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'*'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`request`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` response`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Send the start of your HTML to the browser`}</span>{`
  response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`write`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'<html><head><title>Page</title></head><body><div id="root">'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Render your frontend to a stream and pipe it to the response`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` stream `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`renderToNodeStream`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Frontend`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  stream`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`pipe`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` end`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'false'`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// When React finishes rendering send the rest of your HTML to the browser`}</span>{`
  stream`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`on`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'end'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`end`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'</div></body></html>'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`An example of a streaming server-side rendering route
When I first saw a similar piece of code in the React docs, it was pretty confusing because I'd never worked with Node.js streams before. Let's dig into those for a second.`}</p>
    <h4>{`How Streams Work`}</h4>
    <p><inlineCode parentName="p">{`renderToNodeStream`}</inlineCode>{` returns a readable stream, which I imagine as a faucet: it has an underlying source of data (the tank holding the water) that's waiting to be read by a consumer (waiting for the valve to be opened). To consume a readable stream (open the valve), we would listen to its "data" event, which is triggered whenever there is a new chunk of data to be read. In our case, the source is React rendering our frontend:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` renderToNodeStream `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'react-dom/server'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` stream `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`renderToNodeStream`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Frontend`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

stream`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`on`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'data'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`data`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token console class-name"
        }}>{`console`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`log`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token known-class-name class-name"
        }}>{`JSON`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`stringify`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`data`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`Listening to a rendering stream's data event
Not too complex, eh? Try running that in your app and you'll see chunks of HTML being logged to the console one after the other. Looking at our rendering code above though, we're obviously not listening to the `}<inlineCode parentName="p">{`data`}</inlineCode>{` event manually. Instead, we're `}<em parentName="p">{`piping`}</em>{` the stream into the response object. What?!The response object in Node.js is a writable stream, which I imagine as a drain. When piped into each other, a readable stream essentially passes data in small chunks to the writable stream (imagine attaching a faucet directly to a drain, which itself connects to wherever you want the water to go). Writable streams can then do whatever they want with those chunks of data. In our case, we're piping the React renderer (which outputs HTML) into the response writable stream, which will send the chunks to the requesting client in parallel to waiting for more:`}</p>
    <p><img parentName="p" {...{
        "src": "/static/images/renderToNodeStream.png",
        "alt": null
      }}></img></p>
    <p>{`As you can see above, streaming decreases the Time-To-First-Byte, because the server sends the first bit of HTML sooner, and the Time-To-First-Meaningful-Paint, because the critical markup arrives at the browser earlier due to parallelization. Jackpot! 🏆`}</p>
    <h3>{`Caching HTML in Node.js`}</h3>
    <p>{`For `}<a parentName="p" {...{
        "href": "https://spectrum.chat"
      }}>{`Spectrum`}</a>{`, we cache public pages in Redis to avoid React rendering them a million times over even though it's always the same HTML in the end. We don't cache anything for authenticated users because we install a ServiceWorker on first load, which serves an app shell locally on subsequent page visits, and this app shell fetches data from our API directly—no more server rendering! For public pages we previously monkey-patched `}<inlineCode parentName="p">{`response.send`}</inlineCode>{` to cache them before responding to the user:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` originalSend `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`send`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method-variable function-variable method function property-access"
        }}>{`send`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`html`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="span" {...{
            "className": "token spread operator"
          }}>{`...`}</span>{`other`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Make sure not to cache (possibly temporary) error states`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`statusCode`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`100`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`&&`}</span>{` response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`statusCode`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`300`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` cache`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`put`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`request`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`path`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` html`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
  `}<span parentName="code" {...{
          "className": "token function"
        }}>{`originalSend`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`html`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token spread operator"
        }}>{`...`}</span>{`other`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`Monkey-patching response.send to cache the rendered HTML before returning it to the client
Combined with a tiny Express middleware this allowed us to cache across many instances of our rendering worker:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}>{`app`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`use`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'*'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`request`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` response`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` next`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`cache`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`has`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`request`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`path`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Return the HTML for this path from the cache if it exists`}</span>{`
    cache`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`get`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`request`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`path`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`then`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`html`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`send`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`html`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`else`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Otherwise go on and render it`}</span>{`
    `}<span parentName="code" {...{
          "className": "token function"
        }}>{`next`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}</code></pre>
    <p>{`A tiny middleware for serving cached files
The million dollar question at this point is of course "How do you cache HTML when you're streaming the response?". Apart from the obvious issue that `}<inlineCode parentName="p">{`response.send`}</inlineCode>{` isn't called anymore you also never have access to the entire HTML document. By the time the renderer finishes rendering most of it will long have been sent to the user!`}</p>
    <h4>{`Caching Streamed HTML`}</h4>
    <p>{`Since I wasn't very familiar with streams `}<a parentName="p" {...{
        "href": "https://spectrum.chat/thread/0cd94785-f87c-4171-9bb0-a31c6b66fb45"
      }}>{`I turned to the Node.js community and asked them how to do this`}</a>{` (shoutout to `}<a parentName="p" {...{
        "href": "https://twitter.com/mafintosh"
      }}>{`@mafintosh`}</a>{` and `}<a parentName="p" {...{
        "href": "https://twitter.com/xander76"
      }}>{`@xander76`}</a>{` who patiently explained it all). It turns out there's a third class of streams which allows us to cache the HTML: transform streams. Continuing the faucet analogy, a transform stream could, for example, be a water filter. You pass data (water) to the transform stream, which can transform it (clean it from gunk) before passing it on to the next stream. In our case, we don't actually want to transform the data. Instead, we want to buffer all the HTML chunks of a single request in memory as they come along, then concatenate them together once we're all done, and store the entire HTML document in the cache (imagine a water filter that duplicates each atom and stores it in a tank until all the water has flown). Here's our tiny utility function for creating a caching transform stream:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}><span parentName="code" {...{
          "className": "token keyword module"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Transform`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword module"
        }}>{`from`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`'stream'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` `}<span parentName="code" {...{
          "className": "token function-variable function"
        }}>{`createCacheStream`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`key`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` bufferedChunks `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`new`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Transform`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// transform() is called with each chunk of data`}</span>{`
    `}<span parentName="code" {...{
          "className": "token function"
        }}>{`transform`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`data`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` enc`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` cb`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// We store the chunk of data (which is a Buffer) in memory`}</span>{`
      bufferedChunks`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`push`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`data`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
      `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Then pass the data unchanged onwards to the next stream`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`cb`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token keyword null nil"
        }}>{`null`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` data`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`

    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// flush() is called when everything is done`}</span>{`
    `}<span parentName="code" {...{
          "className": "token function"
        }}>{`flush`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`cb`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// We concatenate all the buffered chunks of HTML to get the full HTML`}</span>{`
      `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// then cache it at "key"`}</span>{`
      cache`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`set`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`key`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Buffer`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`concat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`bufferedChunks`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
      `}<span parentName="code" {...{
          "className": "token function"
        }}>{`cb`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`Usage: `}<inlineCode parentName="p">{`createCacheStream(request.path)`}</inlineCode>{` to cache keyed by the request's path
Now we can use that in our server-side rendering code:`}</p>
    <pre {...{
      "className": "language-js"
    }}><code parentName="pre" {...{
        "className": "language-js"
      }}>{`app`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`use`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'*'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token parameter"
        }}>{`request`}<span parentName="span" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` response`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Create the cache stream and pipe it into the response`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` cacheStream `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`createCacheStream`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`request`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token property-access"
        }}>{`path`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  cacheStream`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`pipe`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Write the first bit of HTML into it`}</span>{`
  cacheStream`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`write`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'<html><head><title>Page</title></head><body><div id="root">'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Create the rendering stream and pipe it into the cache stream`}</span>{`
  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`const`}</span>{` renderStream `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`renderToNodeStream`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token maybe-class-name"
        }}>{`Frontend`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`/`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  renderStream`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`pipe`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`cacheStream`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{` end`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token boolean"
        }}>{`false`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  renderStream`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`on`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'end'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token arrow operator"
        }}>{`=>`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Once it's done rendering write the rest of the HTML`}</span>{`
    cacheStream`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token method function property-access"
        }}>{`end`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`'</div></body></html>'`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{`
`}</code></pre>
    <p>{`An example of how to use the `}<inlineCode parentName="p">{`createCacheStream`}</inlineCode>{` util to cache HTML
As you can see, we're piping the rendering stream (faucet) into the cache stream (duplicating water filter) which passes the HTML on to the response (drain). Also note how we write the beginnging and the end of the HTML to the `}<inlineCode parentName="p">{`cacheStream`}</inlineCode>{` instead of directly to the response to make sure our caching layer is aware of those bits of markup. That's all there is to it, that's how we cache the streamed HTML at `}<a parentName="p" {...{
        "href": "https://spectrum.chat/"
      }}>{`Spectrum`}</a>{`! As always, there's things to improve in any code (this doesn't handle cache stampedes, for example), but it's serving us alright in production today.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      